import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import About from "../components/about/about"

const IndexPage = () => (
  <Layout headerColor="white" inView={true}>
    <Seo />
    <About />
  </Layout>
)

export default IndexPage
