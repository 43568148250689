import React from "react"
import styled from "@emotion/styled"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { StaticImage } from "gatsby-plugin-image"
import { useSpring, animated, config } from "react-spring"

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--primary-orange);
`

const AboutSection = styled.section`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(11, min-content);
  max-width: 1400px;

  @media only screen and (max-width: 1090px) {
    margin-top: 6em;
    margin-bottom: 6em;
    display: flex;
    flex-direction: column;
  }
`

const AboutPicture = styled.div`
  grid-column: 1 / 5;
  grid-row: 1 / 10;
  width: 100%;
  height: 100%;
`

const AboutText = styled.div`
  margin-top: 4em;
  grid-column: 4 / -1;
  grid-row: 4 / 11;
  z-index: 1000;
  color: white;
  background-color: var(--transparent-orange-2);
  padding: 1.5em;

  h2 {
    font-size: 4em;
  }

  p:not(:last-of-type) {
    margin-bottom: 1em;
  }

  @media only screen and (max-width: 1090px) {
    margin-top: 1em;
    padding: 0;
  }
`

const EmailLink = styled.p`
  font-size: 1.5em;
`

const AnimatedPicture = animated(AboutPicture)
const AnimatedText = animated(AboutText)

const About = () => {
  const pictureSpring = {
    to: { opacity: 1, transform: "translate(0px, 0px)" },
    from: { opacity: 0, transform: "translate(0px, 100px)" },
    config: config.wobbly,
    delay: 400,
  }
  const textSpring = {
    to: { opacity: 1, transform: "translate(0px, 0px)" },
    from: { opacity: 0, transform: "translate(100px, 0px)" },
    config: config.wobbly,
    delay: 500,
  }

  return (
    <FlexContainer>
      <AboutSection>
        <AnimatedPicture style={useSpring(pictureSpring)}>
          <StaticImage
            src="../../images/james_pic-orange.jpg"
            alt="James smiling"
          />
        </AnimatedPicture>
        <AnimatedText style={useSpring(textSpring)}>
          <p>
            Howdy! I'm James Mallette. I'm a multidisciplinary designer based
            out of Charlotte, NC. I specialize in graphic design, website
            development, and photo/video work, but I am always striving to learn
            new technologies to create immersive user experiences.
          </p>
          <p>
            I've been passionate about design for as long as I can remember. I
            would spend hours as a child making my own trading cards, animating
            stick figures using Microsoft Paint and Movie Maker, and creating
            "websites" by hyperlinking one Word document to another. I feel
            blessed that my childhood hobbies came full circle and I'm able to
            explore these mediums as a profession.
          </p>
          <p>
            I earned my BFA in graphic design at Appalachian State Univsersity,
            and the mountains of North Carolina will always hold a special place
            in my heart. When I'm not designing, you can find me playing disc
            golf, biking, and seeing live music.
          </p>
          <EmailLink>
            <a href="mailto:jamespmallette@gmail.com" alt="James's Email">
              Email me!
            </a>{" "}
            Let's make something together.
          </EmailLink>
          <AniLink cover direction="right" bg="#FFF" to="/">
            <p style={{ marginTop: "1em" }}>Back home </p>
          </AniLink>
        </AnimatedText>
      </AboutSection>
    </FlexContainer>
  )
}

export default About
